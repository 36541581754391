<template>
    <a-drawer
        class="share_select_driwer"
        :class="[shareUrl && 'show_link', isMobile && 'mobile']"
        :width="isMobile ? '100%' : 380"
        :destroyOnClose="true"
        :zIndex="9999999"
        :placement="isMobile ? 'bottom' : 'right'"
        :title="$t('chat.share')"
        :visible="visible"
        :afterVisibleChange="afterVisibleChange"
        @close="close()">
        <div 
            v-if="!isMobile && shareUrl" 
            class="drawer_share_link">
            <div 
                class="share_btn" 
                v-tippy="{ inertia : true, duration : '[600,300]'}" 
                :content="$t('chat.copy_link')"
                @click="copyLink()">
                <i class="fi fi-rr-link-alt"></i>
            </div>
            <div 
                class="share_btn" 
                v-tippy="{ inertia : true, duration : '[600,300]'}" 
                :content="$t('chat.share_email')"
                @click="emailShare()">
                <i class="fi fi-rr-envelope"></i>
            </div>
            <a-popover :getPopupContainer="getPopupContainer">
                <template slot="content">
                    <qr-code :text="shareUrl" />
                </template>
                <div class="share_btn">
                    <i class="fi fi-rr-vector"></i>
                </div>
            </a-popover>
            <div 
                class="share_btn" 
                v-tippy="{ inertia : true, duration : '[600,300]'}" 
                :content="$t('chat.share_telegram')"
                @click="tgShare()">
                <img src="@/assets/images/telegram.svg" />
            </div>
            <div 
                class="share_btn" 
                v-tippy="{ inertia : true, duration : '[600,300]'}" 
                :content="$t('chat.share_whatsapp')"
                @click="wpShare()">
                <img src="@/assets/images/WhatsApp.svg" />
            </div>
            <OldSelected 
                ref="sharingOldSelector"
                multiple
                titleField="name"
                wrapperClass=""
                avatarIcon="user"
                :showLabel="false"
                :listMaxLenght="isMobile ? 2 : 3"
                customAvatarPath
                avatarField="recipient"
                dbId="sharing"
                :itemSelect="changeSelectValue"
                :getPopupContainer="getPopupContainer" />
        </div>
        <div class="drawer_body" ref="drawerBody">
            <div class="drawer_scroll infinite-wrapper">
                <ul class="bordered-items">
                    <li 
                        class="flex items-center justify-between px-3 py-2 cursor-pointer item"
                        v-for="(dialog, index) in dialogList" 
                        :key="index">
                        <div class="flex items-center justify-between w-full">
                            <div
                                @click="changeSelectValue(dialog)" 
                                class="flex items-center w-full truncate">
                                <template v-if="dialog.is_public">
                                    <div class="mr-2">
                                        <a-avatar :style="dialog.color ? `backgroundColor:${dialog.color}` : 'backgroundColor: #cccccc'">
                                            {{ avatarText(dialog) }}
                                        </a-avatar>
                                    </div>
                                    <div class="truncate">
                                        <div class="text-sm font-semibold truncate">
                                            {{dialog.name}}
                                            <a-icon class="ml-1" style="font-size: 11px;" type="team" />
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <ShareDrawerUser :dialog="dialog" />
                                </template>
                            </div> 
                            <div class="flex items-center">
                                <a-checkbox 
                                    :ref="'checkbox_'+dialog.chat_uid" 
                                    :id="dialog.chat_uid"  
                                    @change="changeSelect($event, dialog)"/>
                            </div>
                        </div>
                    </li>
                </ul>
                <infinite-loading @infinite="getDialogkList" :distance="10">
                    <div slot="spinner">
                        <a-spin
                            size="small"
                            style="margin-top: 10px;" />
                    </div>
                    <div slot="no-more"></div> 
                    <div slot="no-results"></div>
                </infinite-loading>
            </div>
        </div>
        <div 
            v-if="isMobile && shareUrl" 
            class="drawer_share_link">
            <div 
                class="share_btn" 
                @click="copyLink()">
                <i class="fi fi-rr-link-alt"></i>
            </div>
            <div 
                class="share_btn" 
                @click="emailShare()">
                <i class="fi fi-rr-envelope"></i>
            </div>
            <div 
                class="share_btn" 
                @click="tgShare()">
                <img src="@/assets/images/telegram.svg" />
            </div>
            <div 
                class="share_btn" 
                @click="wpShare()">
                <img src="@/assets/images/WhatsApp.svg" />
            </div>
            <div 
                class="share_btn"
                @click="vbShare()">
                <img src="@/assets/images/viber.svg" />
            </div>
            <OldSelected 
                ref="sharingOldSelector"
                multiple
                titleField="name"
                wrapperClass=""
                avatarIcon="user"
                :showLabel="false"
                :listMaxLenght="isMobile ? 2 : 3"
                customAvatarPath
                avatarField="recipient"
                dbId="sharing"
                :itemSelect="changeSelectValue"
                :getPopupContainer="getPopupContainer" />
        </div>
        <div class="drawer_footer share_footer">
            <a-button
                @click="modalVisible = true"
                :block="isMobile"
                :size="isMobile ? 'large' : 'default'"
                class="px-6"
                type="primary"
                :disabled="selectList.length === 0">
                {{ $t('chat.send') }}
            </a-button>
            <a-button
                @click="createChat"
                :class="!isMobile && 'ml-2'"
                :block="isMobile"
                :size="isMobile ? 'large' : 'default'"
                :type="isMobile ? 'ui' : 'link'"
                :ghost="isMobile">
                {{$t('chat.create_chat')}}
            </a-button>
        </div>
        <a-modal
            class="share_modal"
            :visible="modalVisible"
            :getContainer="getPopupContainer"
            @cancel="modalVisible = false">
            <template slot="title">
                <div v-if="selectedChat" class="flex items-center">
                    <div class="flex items-center truncate">
                        <div class="mr-2">
                            <a-avatar
                                v-if="selectedChat.recipient && selectedChat.recipient.avatar"
                                :src="selectedChat.recipient.avatar.path" />
                            <a-avatar icon="user" v-else />
                        </div>
                        <div class="truncate">
                            <div class="text-sm font-semibold truncate">
                                {{selectedChat.name}}
                                <template v-if="selectedChat.is_public">
                                    <a-icon class="ml-1" style="font-size: 11px;" type="team" />
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <div v-if="shareObject">
                <div class="mb-2 font-semibold">
                    <span v-if="shareModel === 'files.files'">
                        {{$t('chat.give_access_to_files')}}:
                    </span>
                    <span v-else>
                        <span>
                            {{$t('chat.attach')}}
                        </span>
                        <span class="lowercase" v-if="shareModel !== 'workgroups.WorkGroupModel'">
                            {{$t(shareModel)}}{{shareObject.name && `: ${shareObject.name}`}}
                        </span>

                        <span class="lowercase" v-else>
                            {{shareObject.is_project ? $t('chat.project') : $t('chat.work_group')}}:  {{shareObject.name}}
                        </span>
                    </span>
                </div>

                <div v-if="shareModel === 'crm.GoodsOrderModel'">
                    <div class="mb-2">
                        {{$t('chat.number')}}: {{ shareObject.counter }}
                    </div>
                    <div v-if="shareObject.contractor" class="mb-2">
                        {{$t('chat.client')}}: {{ shareObject.contractor.name }}
                    </div>
                    <div v-if="shareObject.contract" class="mb-2">
                        {{$t('chat.contract')}}: {{ shareObject.contract.name }}
                    </div>
                    <div v-if="shareObject.execute_status">
                        {{$t('chat.status')}}: {{ shareObject.execute_status.name }}
                    </div>
                </div>

                <div v-if="shareModel === 'tasks.TaskModel'" class="flex items-center">
                    <a-tag class="ml-1" v-if="shareObject.attachments && shareObject.attachments.length">
                        <a-icon type="paper-clip" />
                        {{shareObject.attachments.length}}
                    </a-tag>

                    <template v-else>
                        <a-tag class="m-0">{{$t('chat.no_time_limit')}}</a-tag>
                    </template>
                    <a-tag 
                        v-if="shareObject.status"
                        class="ml-2 mr-0" 
                        :color="shareObject.status.color">
                        {{ shareObject.status.name }}
                    </a-tag>
                </div>
                <template v-if="shareModel === 'files.files'">
                    <div class="flex items-center truncate">
                        <div class="mt-2 attached_files">
                            <div 
                                v-for="(file, index) in [shareObject]" 
                                :key="index"
                                class="attached_file">
                                <a-tooltip :title="`${file.name}.${file.extension}`">
                                    <div class="file_image_wrapper">
                                        <img 
                                            :data-src="file.is_image ? file.path : fileIcon(file)" 
                                            alt=""
                                            class="file_icon lazyload"
                                            :class="file.is_image && 'file_image'">
                                    </div>
                                    <div class="file_name font-light text-center truncate">
                                        {{ file.name }}
                                    </div>
                                </a-tooltip>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="shareModel === 'comments'">
                    <div class="flex items-center mb-2 truncate">
                        <div class="mr-2">
                            <a-avatar
                                v-if="shareObject.author.avatar"
                                :src="shareObject.author.avatar.path" />
                            <a-avatar icon="user" v-else />
                        </div>
                        <div class="truncate">
                            <div class="text-sm font-semibold truncate">
                                {{shareObject.author.full_name}}
                            </div>
                        </div>
                    </div>
                    <div>
                        {{shareObject.text}}
                    </div>
                </template>
                <div v-if="shareModel === 'meetings.PlannedMeetingModel'">
                    <h3 class="text-base">{{shareObject.name}}</h3>
                    <a-tag class="my-1 mr-1">
                        {{$t('chat.duration')}} {{ shareObject.duration}} мин
                    </a-tag>
                    <div 
                        v-if="shareObject.status === 'online'" 
                        class="online flex items-center">
                        <div class="blob mr-2"></div>
                        {{$t('chat.online')}}
                    </div>
                    <a-tag 
                        v-if="shareObject.status === 'new'"
                        class="ml-1"
                        color="green">
                        {{$t('chat.new')}}
                    </a-tag>
                    <a-tag 
                        v-if="shareObject.status === 'ended'"
                        class="ml-1"
                        color="purple">
                        {{$t('chat.ended')}}
                    </a-tag>
                    <a-tag class="my-1 mr-1">
                        {{$t('chat.participants_count')}} - {{ shareObject.members_count }}
                    </a-tag>
                </div>
            </div>
            <div slot="footer">
                <div class="relative w-full footer_share" ref="footerWrapper">
                    <a-textarea
                        class="w-full modal_text"
                        name="modalText"
                        ref="shareText"
                        v-model="text"
                        @keydown="inputHandler"
                        :placeholder="$t('chat.enter_your_message')"
                        :auto-size="false" />
                    <div class="absolute flex items-center footer_action">
                        <a-dropdown :getPopupContainer="() => $refs.footerWrapper">
                            <a-menu slot="overlay">
                                <a-menu-item 
                                    key="1" 
                                    @click="createMessage(false)"> 
                                    <a-icon type="profile" />{{$t('chat.share_and_stay')}} 
                                </a-menu-item>
                                <a-menu-item 
                                    key="2"
                                    @click="createMessage(true)">
                                    <a-icon type="message" />{{$t('chat.share_and_go_to_chat')}}
                                </a-menu-item>
                            </a-menu>
                            <a-button
                                class="lg:ml-2 send_button"
                                type="primary"
                                :loading="createLoader"
                                flaticon
                                icon="fi-rr-paper-plane-top"
                                @click="createMessage(false)"
                                shape="circle" />
                        </a-dropdown>
                    </div>
                </div>
            </div>
        </a-modal>
    </a-drawer>
</template>

<script>
import { filesFormat } from '@/utils'

import {mapState} from 'vuex'
import {statusList} from '@/utils/localeData'
import InfiniteLoading from 'vue-infinite-loading'
import eventBus from '@/utils/eventBus'
import OldSelected from '@apps/DrawerSelect/OldSelected.vue'
import VueQRCodeComponent from 'vue-qrcode-component'
import ShareDrawerUser from './ShareDrawerUser.vue'

export default {
    name: "ChatShareDrawer",
    components: {
        OldSelected,
        InfiniteLoading,
        ShareDrawerUser,
        QrCode: VueQRCodeComponent
    },
    data() {
        return {
            statusList,
            dialogList: [],
            activeDrop: true,
            scrollStatus: true,
            page: 0,
            drawerVisible: false,
            loading: false,
            createLoader: false,
            modalVisible: false,
            selectedChat: null,
            selectList: [],
            selectedIdList: [],
            text: '',
            showMobileShare: false
        }
    },
    computed: {
        ...mapState({
            windowWidth: state => state.windowWidth,
            shareModel: state => state.share.shareModel,
            shareId: state => state.share.shareId,
            shareObject: state => state.share.shareObject,
            chatMessage: state => state.chat.chatMessage,
            bodySelector: state => state.share.bodySelector,
            shareUrl: state => state.share.shareUrl,
            shareTitle: state => state.share.shareTitle,
            isMobile: state => state.isMobile
        }),
        statusType() {
            const status = this.statusList.find(elem => elem.name === this.shareObject.status)
            if(status)
                return status.color
            else
                return 'blue'
        },
        visible: {
            get() {
                return this.$store.state.share.visible
            },
            set(val) {
                this.$store.commit('share/SET_VISIBLE', val)
            }
        },
        checkFileType() {
            if(this.shareObject.is_image)
                return 'file-image'
            if(this.shareObject.is_doc)
                return 'file-text'
            if(this.shareObject.is_video)
                return 'video-camera'
            if(this.shareObject.is_audio)
                return 'audio'

            return 'file'
        }
    },
    watch: {
        modalVisible(val) {
            if(!val)
                this.text = ''
            else {
                this.$nextTick(() => {
                    this.$refs.shareText.$el.focus()
                })
            }
        },
        visible(val) {
            if(!val)
                this.clear()
            else
                this.drawerVisible = true
        },
      
    },
    mounted(){
        eventBus.$on("update_list_share_drawer", ()=>{
            if(this.visible){ 
                this.dialogList =[]
                this.page = 0
                this.getDialogkList()
            }
        })

        if(this.isMobile && navigator.share) {
            this.showMobileShare = true
        }
    },
    
    methods: {
        avatarText(dialog) {
            if(dialog) {
                if(dialog.is_public) {
                    return dialog.name.charAt(0).toUpperCase()
                } else {
                    const n = dialog.name.split(' ')
                    return `${n[0].charAt(0).toUpperCase()}${n[1] ? n[1].charAt(0).toUpperCase() : ''}${n[2] ? n[2].charAt(0).toUpperCase() : ''}`
                }
            }
            return ''
        },
        mobileShare() {
            const shareData = {
                text: this.shareTitle,
                url: this.shareUrl
            }

            navigator.share(shareData)
                .then(() => console.log('Share success'))
                .catch((error) => console.log('Error sharing', error))
        },
        emailShare() {
            window.open(`mailto:?subject=${this.shareTitle}&body=${this.shareTitle} - ${this.shareUrl}`, '_blank').focus()
        },
        wpShare() {
            window.open(`https://wa.me/?text=${this.shareTitle} - ${this.shareUrl}`, '_blank').focus()
        },
        tgShare() {
            window.open(`https://t.me/share/url?url=${this.shareUrl}&text=${this.shareTitle}`, '_blank').focus()
        },
        vbShare() {
            window.open(`viber://forward?text=${this.shareTitle} - ${this.shareUrl}`, '_blank').focus()
        },
        copyLink() {
            try {
                navigator.clipboard.writeText(this.shareUrl)
            } catch(e) {
                console.log(e)
            }
        },
        getPopupContainer() {
            return this.$refs.drawerBody
        },
        afterVisibleChange(val) {
            if(!val) {
                this.drawerVisible = false
            }
        },
        inputHandler(e) {
            if (e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault()
                this.createMessage()
            }
        },
        changeSelectValue(dialog){
            // this.$nextTick(()=>{
            // let value = this.$refs['checkbox_'+ dialog.chat_uid][0].$el.control.checked
            // if(value){
            this.selectList.push(dialog.chat_uid)
            this.selectedIdList.push(dialog.id)

            if(this.$refs.sharingOldSelector)
                this.$refs.sharingOldSelector.saveSelect(dialog)
                 
            // } else {
            //     this.selectList = this.selectList.filter(el=> el !== dialog.chat_uid)
            // }
            this.modalVisible = true
            // })
           
        },
        changeSelect(val, dialog){
            let value = val.target.checked
            if(value){
                this.selectList.push(val.target.id)
                this.selectedIdList.push(dialog.id)
            } else {
                this.selectList = this.selectList.filter(el=> el !== val.target.id)
                this.selectedIdList = this.selectList.filter(el=> el !== dialog.id)
            }

            if(this.dialogList.length) {
                const find = this.dialogList.find(f => f.chat_uid === val.target.id)
                if(find && this.$refs.sharingOldSelector)
                    this.$refs.sharingOldSelector.saveSelect(find)
            }
        },
        createChat(){
            this.$store.commit('chat/TOGGLE_CREATE_CHAT', true)
        },
        async createMessage(value) {
            try {
                this.createLoader = true
                this.selectList.forEach(el => {
                    const queryParams = this.createMessageParams(el)
                    this.$socket.client.emit("message", queryParams)
                    // this.$store.commit('chat/ADD_MESSAGE_BY_ID', {chat_uid: this.selectList[0], value: queryParams})
                });

                if(this.shareModel === 'tasks.TaskModel')
                    this.$message.success(this.$t('chat.task_sent'))
                if(this.shareModel === 'files.files') {
                    this.$message.success(this.$t('chat.file_sent'))
                    this.selectedIdList.forEach(chatId => {
                        this.$store.commit('files/CLEAR_ALL', chatId)
                    })

                }
                if(this.shareModel === 'comments')
                    this.$message.success(this.$t('chat.comments_sent'))
               

                if(value){ 
                    this.createLoader = true
                    setTimeout(() => {
                        if(this.isMobile) {
                            this.$router.push({
                                name: 'chat-body',
                                params: {
                                    id: this.selectList[0]
                                }
                            })
                        } else {
                            if(this.$route.name !=='chat' && this.$route.query.chat_id !== this.selectList[0])
                                this.$router.push({name: 'chat', query: {chat_id: this.selectList[0]}})  
                        }

                        this.close()
                        this.createLoader = false
                    }, 10);
                } else {
                    this.close()
                }
               
                
            } catch(e) {
                console.log(e)
                // this.$message.error(this.$t('error') + e)
            } finally {
                this.createLoader = false
            }
        },
        createMessageParams(chat) {
            const messageParams = {
                text: this.text,
                chat: chat                    
            }
            const shareParams = {
                share_id: this.shareId,
                ...this.shareObject,
                type: this.shareModel
            }

            const isFile = this.shareModel === 'files.files'
            if(isFile) {
                messageParams.attachments = [shareParams]
            } else {
                messageParams.share = shareParams
            }
            return messageParams
        },
        clear() {
            this.text = ''
            this.selectedChat = null
            this.dialogList = []
            this.scrollStatus = true
            this.page = 0
            this.selectList = []
            this.$store.commit('share/CLEAR_PARAMS')
        },
        selectDialog(dialog) {
            this.selectedChat = dialog
            this.modalVisible = true
        },
        close() {
            this.modalVisible = false
            this.visible = false
            this.selectList = []
            this.selectedIdList.splice(0)
        },
        async getDialogkList($state = null) {
            if(!this.loading && this.scrollStatus) {
                try {
                    this.loading = true
                    this.page = this.page+1
                    let params = {
                        page_size: 15,
                        page: this.page
                    }

                    const { data } = await this.$http.get('/chat/list/', { params })
                    if(data && data.results.length) {
                        this.dialogList = this.dialogList.concat(data.results)
                    }
                   
                    if(!data.next) {
                        if($state)
                            $state.complete()
                        this.scrollStatus = false
                    } else {
                        if($state)
                            $state.loaded()
                    }
                } catch(e) {

                } finally {
                    this.loading = false
                }
            } else {
                if($state)
                    $state.loaded()
            }
        },
        fileIcon(file) {
            // if(this.isFolder)
            //     return require(`@/assets/images/files/folder.svg`)

            const extension = filesFormat.find(format => format === file.extension)
            if(extension)
                return require(`@/assets/images/files/${extension}.svg`)
            else
                return require(`@/assets/images/files/file.svg`)
        },
    }
}
</script>

<style lang="scss">
.footer_share{
    textarea{
        resize: none;
    }
    .footer_action{
        right: 15px;
        top: 50%;
        margin-top: -16px;
    }
    .send_chat_btn{
        &.ant-btn-loading{
            img{
                display: none;
            }
        }
    }
    
}
.ant-dropdown{
        z-index: 99999;
}
.send_button{
                display: flex;
                align-items: center;
                justify-content: center;
                .feather{
                    margin-top: 2px;
                    margin-right: 2px;
                }
            }
.share_select_driwer{
    .ant-drawer-content,
    .ant-drawer-wrapper-body{
        overflow: hidden;
    }
    &.show_link{
        .drawer_share_link{
            height: 50px;
            border-bottom: 1px solid var(--border2);
            padding: 5px 15px;
            display: flex;
            align-items: center;
            .share_btn{
                cursor: pointer;
                width: 35px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #eff2f5;
                border-radius: 50%;
                img{
                    max-width: 18px;
                    height: auto;
                }
                &:not(:last-child){
                    margin-right: 8px;
                }
            }
        }
        .drawer_body{
            height: calc(100% - 90px);
        }
    }
    &:not(.show_link) {
        .drawer_body{
            height: calc(100% - 40px);
        }
    }
    .ant-drawer-body{
        padding: 0px;
        height: calc(100% - 40px);
        .drawer_footer{
            padding: 0 15px;
            display: flex;
            align-items: center;
            border-top: 1px solid var(--border2);
            height: 40px;
        }
        .drawer_scroll{
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
    &.mobile{
        .ant-drawer-content-wrapper{
            height: 100%!important;
        }
        &.show_link{
            .drawer_body{
                height: calc(100% - 150px);
            }
        }
        &:not(.show_link) {
            .drawer_body{
                height: calc(100% - 100px);
            }
        }
        .drawer_share_link{
            border-bottom: 0px;
            border-top: 1px solid var(--border2);
        }
        .drawer_footer{
            height: 10px;
            display: block;
            padding-top: 5px;
            padding-bottom: 5px;
            border-top: 0px;
            .ant-btn{
                &:last-child{
                    margin-top: 5px;
                }
            }
        }
    }
}
</style>


<style scoped lang="scss">
    .attached_file {
        position: relative;

        border: 1px solid #d9d9d9;
        border-radius: 4px;
        .file_image_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
    
            height: 70px;
            
            padding: 8px;
            .file_icon {
                width: 100%;
                max-height: 100%;
                
                object-fit: contain;
            }
            .file_image {
                border: 1px solid var(--borderColor);
                border-radius: 4px;
            }
        }
        .file_name {
            margin-top: 4px;
            padding: 0 8px;

            line-height: 1.1;
        }
    }
</style>